@default-min-width: 250px;

.grid {
  .lay-row();
  .--gap();
  .--stretch-h();

  width: 100%;

  & > * {
    flex: 1 0 0%;

    &.col-2-3 {
      flex: 0 1 calc(66.66666% - @spacing / 2);
    }
  }

  &.--2 {
    & > * {
      flex: 0 0 calc((100% - (1 * @spacing)) / 2);

      @media (max-width: @bp-s-max) {
        flex: 0 0 100%;
      }
    }
  }

  &.--3 {
    & > * {
      flex: 0 0 calc((100% - (2 * @spacing)) / 3);

      @media (max-width: @bp-m-max) {
        flex: 0 0 calc((100% - (1 * @spacing)) / 2);
      }

      @media (max-width: @bp-xs-max) {
        flex: 0 0 100%;
      }
    }
  }

  &.--4 {
    & > * {
      flex: 0 0 calc((100% - (3 * @spacing)) / 4);

      @media (max-width: @bp-m-max) {
        flex: 0 0 calc((100% - (1 * @spacing)) / 2);
      }

      @media (max-width: @bp-xs-max) {
        flex: 0 0 100%;
      }
    }
  }

  &.--5 {
    & > * {
      flex: 0 0 calc((100% - (4 * @spacing)) / 5);

      @media (max-width: @bp-m-max) {
        flex: 0 0 calc((100% - (3 * @spacing)) / 4);
      }

      @media (max-width: @bp-s-max) {
        flex: 0 0 calc((100% - (2 * @spacing)) / 3);
      }

      @media (max-width: @bp-xs-max) {
        flex: 0 0 calc((100% - (1 * @spacing)) / 2);
      }
    }
  }

  &.--2-cols {
    & > * {
      flex: 0 1 calc(50% - @spacing / 2);
      // put flex: 1 1 xxx for a form where a column can stretch if it is the last of it's row
    }
  }

  &.--3-cols {
    @min-width: @default-min-width;
    & > * {
      width: calc(33% - 2 * @spacing);
      min-width: @min-width;

      @media (max-width: @min-width) {
        width: 100%;
        min-width: auto;
      }
    }
  }

  &.--xs {
    @media (max-width: @bp-xxs-max) {
      .lay-col();

      & > * {
        width: 100%;
        max-width: none;
      }
    }
  }

  &.--s {
    @media (max-width: @bp-xs-max) {
      .lay-col();

      & > * {
        width: 100%;
        max-width: none;
      }
    }
  }

  &.--m {
    @media (max-width: @bp-s-max) {
      .lay-col();

      & > * {
        width: 100%;
        max-width: none;
      }
    }
  }

  &.--l {
    @media (max-width: @bp-m-max) {
      .lay-col();

      & > * {
        width: 100%;
        max-width: none;
      }
    }
  }

  .--min-280 {
    min-width: 280px;
  }
}
