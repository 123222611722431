.list {
  .list-item {
    display: block;
    padding: @spacing-s;
    background: white;

    .item-title {
      .body();
      .body-strong();
      .truncate();

      color: @dark-grey;
    }

    .item-meta-primary {
      .body-s();
      .body-strong();
      .truncate();

      color: @grey;
    }

    .item-meta-secondary {
      .body-xs();
      .body-strong();
      .truncate();

      color: @light-grey;

      &:not(& + .item-meta-secondary) {
        margin-top: @spacing-xxs;
      }
    }

    & + .list-item {
      margin-top: @spacing-xxs;
    }

    &:hover {
      background: @xx-light-grey;
      opacity: 1;
    }
  }
}

.list_head {
  .lay-row();
  .--v-bottom();
  .--gap();

  padding: @spacing 0 0;

  .results-count {
    .body-s();

    color: @grey;
  }
}

.documents-list {
  .lay-col();
  .--stretch();
  .--stretch-items();
  .--gap--s();
}

.offers-list {
  scroll-margin-top: @spacing-xxl;
}
