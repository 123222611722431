.page_head {
  .lay-row();
  .--gap();
  .--v-bottom();
  .--distribute-h();

  padding-bottom: @spacing;
  border-bottom: 1px solid @x-light-grey;

  .page_head-actions {
    .--pos-r();
    .lay-row();
    .--gap--xs();
  }
}
