@import "variables";
@import "helpers";
@import "colors";
@import "typo";
@import "layout";
@import "buttons";
@import "cards";
@import "forms";
@import "shadows";
@import "dialogs";
@import "infos";
@import "tabs";
@import "lists";
@import "auth";
@import "pages";
@import "tables";
@import "lightbox";
@import "components";
@import "pictures-gallery";
@import "animations";
@import "datatable";

@import "dashboard";
@import "exchange";
@import "offer";

.section {
  padding-top: @spacing;
  padding-bottom: @spacing;
}
.section-s {
  padding-top: @spacing-s;
  padding-bottom: @spacing-s;
}

.icon {
  width: 20px;
  max-height: 20px;
}

.file {
  .lay-row();
  .--gap();
  .--center-v();
  .--distribute-h();

  padding: @spacing-xs @spacing-s;
  background: white;
  border: 1px solid @x-light-grey;
  border-radius: @radius-l;

  .file-name {
    .link;
    .--s();
    .lay-row();
    .--center-v();

    &::before {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      margin-right: @spacing-xxs;
      content: "";
      background: url(../img/icons/icon-attachment-purple.svg) no-repeat center
        center;
      background-size: contain;
    }
  }

  .delete-file {
    .link;
    .--xs();
    .--txt-danger();
  }
}

/* specifics */
.attachments-grid {
  .file-drop {
    justify-content: space-between;
    min-height: 200px;
  }
}

.media-grid {
  .grid();
  .--5();

  .media-item {
    position: relative;
    display: block;

    .btn-lightbox {
      .ghost-btn();
      .btn-block();

      height: 100%;

      &:hover {
        opacity: 0.7;
      }
    }

    canvas {
      width: 100%;
      border-radius: @spacing-xxs;
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    }

    .delete-img-btn {
      ._btn(fade(white, 75%));

      position: absolute;
      top: @spacing-xs;
      right: @spacing-xs;
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      padding: 0;
      font-size: 20px;
      font-weight: 500;
      color: @red;
      border: none;

      &:not(:disabled):not(.disabled):not([disabled]):not(.selected):hover {
        color: white;
        background: @red;
      }
    }
  }
}

.sep {
  border-top: 1px solid @light-grey;
}

.kpi {
  .title-m();

  color: @purple;
  text-align: center;

  .kpi_label {
    .body-xs();

    color: @grey;
  }
}
