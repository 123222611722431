@whitish: #fafafa; // "pseudo white"
@dark: #222222;

// grey shades
@xxx-light-grey: #f7f8f9;
@xx-light-grey: #e4e5e6;
@x-light-grey: #d6d6d6;
@light-grey: #b0b2b4;
@medium-grey: #96999b;
@grey: #848484;
@dark-grey: #636668;
@x-dark-grey: #4a4c4e;
@xx-dark-grey: #313334;

// charter colors
@purple: #7166ee;
@x-light-purple: #f0effe;
@green: #26a69a;
@light-green: mix(@green, white, 20%);
@yellow: #ffb300;
@light-yellow: #fff8e1;
@red: #e53139;
@light-red: mix(@red, white, 15%);

// text helpers
.--txt-success {
  color: @green;
}
.--txt-warning {
  color: @yellow;
}
.--txt-error,
.--txt-danger {
  color: @red;
}
