.pictures-gallery {
  position: relative;
  height: 450px;
  max-height: 50vh;
  overflow-x: hidden;

  .btn-scroll {
    position: absolute;
    top: 0;
    width: 70px;
    height: 100%;
    cursor: pointer;
    border: none;

    &::after {
      display: inline-block;
      width: 30px;
      height: 30px;
      content: "";
      background: url(../img/icons/icon-arrow-right-square-purple.svg) no-repeat
        center center;
      background-size: contain;
      transition: width 0.2s, height 0.2s;
    }

    &.--left {
      left: 0;
      background: linear-gradient(to left, transparent, white);

      &::after {
        margin-right: @spacing;
        transform: rotate(180deg);
      }
    }
    &.--right {
      right: 0;
      background: linear-gradient(to right, transparent, white);

      &::after {
        margin-left: @spacing;
      }
    }

    &:hover {
      &::after {
        width: 34px;
        height: 34px;
      }
    }
  }
}

.pictures-scroller {
  .lay-row();
  .--gap--s();
  .--stretch();
  .--no-wrap();

  height: 100%;
  transition: transform 0.3s linear;

  .pic {
    all: unset;
    cursor: pointer;
    background: @x-light-purple no-repeat center center;
    background-size: cover;

    &.--main {
      flex: 1 0 50vw;
    }
  }

  .pictures-grid {
    display: flex;
    flex: 1 0 50vw;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: @spacing-s;
    column-gap: @spacing-s;

    .pic {
      flex: 1 0 40%;
      min-width: 50%;
    }
  }
}
