.lightbox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  padding: 10vh @spacing;
  text-align: center;
  cursor: pointer;
  background-color: fade(black, 80%);
  animation: fadein 0.2s linear;

  .content-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .btn-prev {
      left: 0;
      transform: translateY(-50%) rotate(180deg);
    }
    .btn-next {
      right: 0;
      transform: translateY(-50%);
    }

    .btn-prev,
    .btn-next {
      .ghost-btn();

      position: absolute;
      top: 50%;
      display: inline-block;
      width: 40px;
      height: 40px;
      content: "";
      background: url(../img/icons/icon-arrow-right-square-white.svg) no-repeat
        center center;
      background-size: contain;
      transition: width 0.2s, height 0.2s, opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .close-btn {
    .ghost-btn();

    position: absolute;
    top: 0;
    right: 0;
    padding: @spacing @spacing-xs;
  }
}
