.modal-overlay {
  .fade-in();

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: @spacing-xl @spacing @spacing;
  background: fade(black, 60%);
}

body.modal-open {
  height: 100vh;
  padding-right: 15px;
  overflow-y: hidden;

  .modal-overlay {
    overflow-y: auto;
  }
}

.dialog {
  position: relative;
  z-index: 2;

  .close-btn {
    .link();

    position: absolute;
    top: -@spacing-m;
    right: 0;
    left: auto;

    .icon {
      width: 15px;
      max-height: 15px;
    }
  }
  &.panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    max-width: calc(100% - @spacing-xl);
    height: 100vh;
    border-radius: 0;

    .close-btn {
      top: @spacing-xs;
      right: auto;
      left: -@spacing-l;
    }
  }
}
