.table-container {
  overflow-x: auto;
}

.table {
  .body();

  width: 100%;
  color: @dark;
  text-align: left;
  vertical-align: top;
  border-collapse: collapse;
  border-color: @xx-light-grey;

  th,
  td {
    .body();

    padding: @spacing-xs;
  }

  th {
    color: white;
  }

  td {
    border-bottom: 1px solid @xx-light-grey;
  }

  .table-recap-row {
    .body-strong();

    color: white;
    background: @dark-grey;
    border-color: @x-light-grey;

    th,
    td {
      padding-top: @spacing-xxs;
      padding-bottom: @spacing-xxs;
    }
  }

  td.unused-cell {
    background-color: #0000000a;
  }

  thead {
    vertical-align: bottom;
    background: @purple;
  }

  tbody > tr:not(.table-recap-row):nth-child(2n) {
    background: fade(black, 2%);
  }

  .cell-fit {
    width: 0.1%;
    white-space: nowrap;
  }

  [class^="btn"] {
    .btn();
    .--btn-s();
  }
}
