._shadow(@size: @spacing-s) {
  box-shadow: 0 @size @size*1.3 -@size*0.8 fade(black, 10%);
}
._shadow-left(@size: @spacing-s) {
  box-shadow: -@size / 3 0 @size*1.3 0 fade(black, 10%);
}
._shadow-right(@size: @spacing-s) {
  box-shadow: @size / 3 0 @size*1.3 0 fade(black, 10%);
}

.shadow() {
  ._shadow(@spacing-s);
}

.shadow-xs() {
  ._shadow(@spacing-xxs);
}

.shadow-s() {
  ._shadow(@spacing-xs);
}

.shadow-m() {
  ._shadow(@spacing);
}

.shadow-l {
  ._shadow(@spacing-m);
}

.shadow-xl {
  ._shadow(@spacing-l);
}

.shadow-left() {
  ._shadow-left(@spacing-s);
}
.shadow-left-s() {
  ._shadow-left(@spacing-xs);
}

.shadow-right() {
  ._shadow-right(@spacing-s);
}
