@icon-size: 17px;

.info {
  .lay-row();
  .--gap();
  .--stretch-v();
  .--center-v();
  .shadow-xs();

  position: relative;
  width: calc(100% - @icon-size);
  min-height: @icon-size*3 + @spacing-s*2;
  padding: @spacing-s @spacing @spacing-s @icon-size+ @spacing-m;
  margin-left: @icon-size;
  color: @dark;
  white-space: normal;
  background: @x-light-purple;
  border-radius: @radius;

  &::before {
    position: absolute;
    top: @icon-size;
    left: -@icon-size;
    display: block;
    width: @icon-size;
    height: @icon-size;
    padding: @spacing-s;
    content: "";
    background: @purple url("../img/icons/icon-info-white.svg") no-repeat center
      center;
    background-size: @icon-size*1.25;
    border-radius: 100%;
  }

  .info_title {
    .title-xs();
  }

  &,
  p {
    .body-s();
  }

  .info_actions {
    .--pos-r();
    .lay-col();
    .--gap();
    .--center();
  }

  &.--secondary {
    background: @grey;
  }

  &.info--success {
    color: @dark;
    background: @light-green;

    &::before {
      background-color: @green;
      background-image: url("../img/icons/icon-check-white.svg");
    }
  }
  &.info--warning {
    color: @yellow;
    background: @light-yellow;

    &::before {
      background-color: @yellow;
      background-image: url("../img/icons/icon-error-white.svg");
    }
  }
  &.info--error {
    color: @dark;
    background: @light-red;

    &::before {
      background-color: @red;
      background-image: url("../img/icons/icon-error-white.svg");
    }
  }

  // modifiers
  &.info-s {
    padding: @spacing-xs @spacing-s;
  }

  @media (max-width: @bp-xs-max) {
    width: 100%;
    padding-top: @icon-size + @spacing;
    padding-left: @spacing;
    margin-top: @icon-size*2;
    margin-left: 0;

    &::before {
      top: -@icon-size;
      left: @spacing;
    }
  }

  @media (max-width: @bp-xxs-max) {
    .info_actions {
      width: 100%;
      .btn {
        .btn-block();
      }
    }
  }
}

.info-loading {
  .info();
  .info--warning();

  position: fixed;
  top: @spacing;
  left: 50%;
  z-index: 1000;
  justify-content: center;
  width: 280px;
  padding-right: @icon-size+ @spacing-m;
  transform: translateX(-50%);

  &::before {
    .spin();

    background-image: url("../img/icons/icon-spinner-white.svg");
  }

  button {
    margin-left: @spacing-s;
  }

  @media (max-width: @bp-xs-max) {
    width: 90%;
  }
}
