/* alignment helpers */
.--txt-center {
  text-align: center;
}
.--txt-right {
  text-align: right;
}
.--txt-left {
  text-align: left;
}

/* miscellaneous helpers */
.truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.--below-s {
  display: none;

  @media (max-width: @bp-s-max) {
    display: initial;
  }
}

.m-top {
  margin-top: @spacing;
}

.hide-xs {
  @media (max-width: @bp-xs-max) {
    display: none;
  }
}
