.dashboard-header {
  .section-l();
  .container();

  padding-bottom: 0;
  background-color: @xxx-light-grey;
  background-image: url(../img/autolity-bg-logo-white.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 400px;

  .central-image {
    width: 200px;
    max-width: 100%;
  }
}
