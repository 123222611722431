.tabs {
  .lay-row();
  .--center-h();

  row-gap: @spacing-xs;
  column-gap: @spacing;
}

.tab-item {
  .link();
  .--s();
  .semibold();

  padding: 0 @spacing-xxs;
  color: @medium-grey;
  text-transform: uppercase;

  &.active,
  &.active:hover {
    color: @dark;
    border-bottom: none;

    &::after {
      display: block;
      width: 100%;
      height: 3px;
      margin-top: calc(@spacing-xxs - 2px);
      content: "";
      background: @purple;
    }
  }
}
