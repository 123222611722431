.chip {
  .body-s();
  .--txt-center();

  padding: @spacing-xxs @spacing-s;
  border-radius: @radius-xl;
  // TODO: Default style ?

  &.--outline {
    color: @light-grey;
    border: 1px solid @light-grey;
  }

  &.--pending {
    color: @purple;
    border-color: @purple;
  }

  &.--success {
    color: @green;
    border-color: @green;
  }

  &.--warning {
    color: @yellow;
    border-color: @yellow;
  }

  &.--danger {
    color: @red;
    border-color: @red;
  }
}

.pagination {
  .section();
  .lay-row();
  .--center-h();

  & .btn {
    width: @spacing-l * 1.15;
    height: @spacing-l * 1.25;
    color: @purple;
    background-color: transparent;
    border: none;

    &.active {
      color: white;
      background-color: @purple;
    }

    &:hover {
      color: white;
    }

    &:disabled {
      cursor: not-allowed;
      filter: grayscale(100%) brightness(1.5);
    }
  }
}

.plan-cta {
  .section-s();
  .--bg();
  .--light();
  .--txt-center();

  .plan-name {
    .title-xs();

    color: @purple;
  }
}
