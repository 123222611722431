@import "new-grids";

@container-padding: @spacing-xl;
@container-padding-responsive: @spacing;

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  padding-right: @container-padding;
  padding-left: @container-padding;

  &.--no-padding {
    padding-right: 0;
    padding-left: 0;
  }

  @media (max-width: @bp-m-max) {
    padding-right: @container-padding-responsive;
    padding-left: @container-padding-responsive;
  }
}

.container-wrap {
  .container();

  max-width: @bp-xl-min;
  margin: 0 auto;
}

.container-wrap--s {
  .container();

  max-width: @bp-m-max;
  margin: 0 auto;
}

._section(@size: @spacing) {
  padding-top: @size;
  padding-bottom: @size;

  &.--bg {
    padding-right: @size;
    padding-left: @size;
    color: white;
    background-color: @purple;
    border-radius: @radius-l;

    &.--light {
      color: initial;
      background-color: @x-light-purple;
      border: 1px solid @purple;
    }

    &.--grey {
      color: @dark;
      background-color: @xxx-light-grey;
      border: 1px solid @xx-light-grey;
    }

    @media (max-width: @bp-xs-max) {
      padding-right: @spacing;
      padding-left: @spacing;
    }
  }

  &.--sep {
    border-top: 1px solid @light-grey;
  }
}

.section {
  ._section(@spacing);
}
.section-xs {
  ._section(@spacing-xs);
}
.section-s {
  ._section(@spacing-s);
}
.section-l {
  ._section(@spacing-l);
}
.section-xl {
  ._section(@spacing-xl);
}

._content-block(@size: @spacing) {
  margin-top: @size;
  margin-bottom: @size;
}

.content-block {
  ._content-block(@spacing);
}
.content-block-xs {
  ._content-block(@spacing-xs);
}
.content-block-s {
  ._content-block(@spacing-s);
}

.content-block-xl {
  ._content-block(@spacing-xl);
}

.page-content {
  padding-top: @spacing-l;
  padding-bottom: @spacing-l;

  & > [class^="section"]:first-child,
  & > [class^="block"]:first-child {
    padding-top: 0;
  }
}

.lay-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  &.--stretch-v {
    justify-content: stretch;
  }

  .--stretch-v {
    flex: 1 0 auto;
  }
  .--stretch-h {
    width: 100%;
  }
  .--stretch {
    .--stretch-v();
    .--stretch-h();
  }
  .--stretch-items {
    & > * {
      width: 100%;
    }
  }

  &.--gap-h {
    column-gap: @spacing;
  }
  &.--gap-h--s {
    column-gap: @spacing-s;
  }
  &.--gap-v {
    row-gap: @spacing;
  }
  &.--gap-v--s {
    row-gap: @spacing-s;
  }
  &.--gap-v--xs {
    row-gap: @spacing-xs;
  }
  &.--gap {
    .--gap-h();
    .--gap-v();
  }
  &.--gap--s {
    .--gap-h--s();
    .--gap-v--s();
  }
  &.--gap-v--m {
    row-gap: 30px;
  }

  &.--center-v {
    justify-content: center;
  }

  &.--center-h {
    align-items: center;
  }

  &.--center {
    .--center-v();
    .--center-h();
  }
  &.--right {
    align-items: flex-end;
  }
}
.lay-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  &.--no-wrap {
    flex-wrap: nowrap;
  }

  &.--stretch-v {
    align-items: stretch;
  }

  &.--stretch-h {
    & > * {
      flex-grow: 1;
    }
  }
  &.--stretch {
    .--stretch-v();
    .--stretch-h();
  }

  &.--distribute-h {
    justify-content: space-between;
  }

  &.--center-v {
    align-items: center;
  }
  &.--center-h {
    justify-content: center;
  }
  &.--v-bottom {
    align-items: end;
  }

  &.--gap-h--xs {
    column-gap: @spacing-xs;
  }
  &.--gap-h--s {
    column-gap: @spacing-s;
  }
  &.--gap-h {
    column-gap: @spacing;
  }
  &.--gap-h--l {
    column-gap: @spacing-l;
  }
  &.--gap-h--xl {
    column-gap: @spacing-xl;
  }
  &.--gap-v {
    row-gap: @spacing;
  }
  &.--gap-v--s {
    row-gap: @spacing-s;
  }
  &.--gap-v--xs {
    row-gap: @spacing-s;
  }

  &.--gap {
    .--gap-h();
    .--gap-v();
  }

  &.--gap--s {
    .--gap-h--s();
    .--gap-v--s();
  }

  &.--gap--xs {
    .--gap-h--xs();
    .--gap-v--xs();
  }

  .--pos-r {
    margin-left: auto;
  }

  & > .--grow-v {
    flex-grow: 1;
  }
}

._screen-layout() {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  background-color: @app-bg-color;
}

.site-layout {
  ._screen-layout();
  .lay-col();

  .view {
    .--stretch();
  }
}

.header {
  .lay-row();
  .--center-v();
  .--gap-h();
  .container();

  z-index: 1;
  height: 100px;
  padding-top: @spacing-xs;
  padding-bottom: @spacing-xs;
  background-color: white;

  .logo {
    display: block;
    max-width: 200px;
    height: @spacing-l;

    @media (max-width: @bp-xs-max) {
      max-width: 120px;
    }
  }

  nav {
    .lay-row();
    .--center-v();
    .--gap-h();

    &.nav-right {
      .--pos-r();

      .mobile {
        display: none;
      }
    }
  }

  .main-nav {
    background: white;
    transition: left 0.2s linear;

    .logo {
      display: none;
    }
    .close-btn {
      display: none;
    }
  }

  .nav-link {
    ._link(@dark);
  }

  &.--private {
    height: 70px;
    background-color: @purple;

    .nav-link {
      ._link(white);
    }

    .main-nav {
      background: @purple;
    }

    .nav-right {
      column-gap: @spacing-xs;
    }
  }

  .menu-btn {
    .ghost-btn();

    display: none;
  }

  @media (max-width: @bp-s-max) {
    .menu-btn {
      display: block;
    }

    .main-nav {
      .container();
      .lay-col();
      .--gap();
      .shadow-right();

      position: fixed;
      top: 0;
      left: -100%;
      max-width: 300px;
      height: 100vh;
      padding: 90px @spacing;

      .logo {
        position: absolute;
        top: @spacing;
        display: block;
        height: 20px;
      }

      .close-btn {
        .ghost-btn();

        position: absolute;
        top: @spacing-xs;
        right: @spacing;
      }

      .section.--bg {
        .lay-col();
        .--gap();
        .--center-h();

        width: 100%;
        margin-bottom: @spacing;

        .nav-link.active {
          border-bottom: none;
        }

        .btn-1 {
          width: 100%;
        }
      }

      &.--opened {
        left: 0;
        z-index: 3;
      }
    }
  }

  &.--public {
    @media (max-width: @bp-s-max) {
      .main-nav .nav-link {
        .semibold();

        color: @purple;
      }
      .nav-right {
        display: none;
      }
    }
  }
}

.footer {
  .container();
  .section-xl();

  color: white;
  background: @purple;

  .columns {
    .lay-row();

    column-gap: 100px;

    .col-brand {
      max-width: 350px;
    }

    .col-lang {
      .--pos-r();

      max-width: 200px;
    }
  }

  .logo {
    height: @spacing-m;
  }

  nav {
    .lay-col();
    .--gap-v--xs();

    flex: 0 1 auto;

    a {
      .link();
      .--s();
      .--underlined();

      display: flex;
      gap: @spacing-xs;
      align-items: center;
      color: white;
    }
  }

  a {
    color: white;
  }
}
