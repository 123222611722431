.offer-item {
  .lay-row();
  .--stretch();
  .--gap--s();

  margin: @spacing-s 0;
  color: black;
  text-decoration: none;
  border: 1px solid @xx-light-grey;
  border-radius: @radius-xl;
  transition: background-color 0.2s linear;

  .offer-item_photo {
    position: relative;
    flex: 0 0 150px;
    overflow: hidden;
    border-radius: @radius-xl 0 0 @radius-xl;

    .offer-item_photo_image {
      min-width: 150px;
      height: 100%;
      min-height: 100px;
      background: @purple no-repeat center center;
      background-image: url(../img/broken-image-white.svg);
      background-size: cover;
    }

    .offer-favorite-button {
      ._btn();
      .--icon();
      .--bg-blurred();

      position: absolute;
      top: @spacing-xs;
      left: @spacing-xs;
    }

    @media (max-width: 656px) {
      flex: 1 0 100%;
      height: 200px;
      border-top-right-radius: @radius-xl;
      border-bottom-left-radius: 0;
    }
  }

  .offer-body {
    .lay-row();
    .--gap--s();

    flex: 10 1 450px;
    padding: @spacing-xs;

    .offer-body_main {
      .lay-col();

      flex: 1 1 @bp-xs-max;
    }

    .offer-ref {
      padding: @spacing-xxs @spacing-s;
      color: @light-grey;
    }

    .offer-country-flag {
      display: inline-block;
      width: 1.5em;
      height: 1.2em;
      margin-bottom: -0.25em;
      background-position: center;
      background-size: cover;
    }
  }

  .offer-body_separator {
    flex-grow: 0;
    margin-right: -@spacing-s;

    @media (min-width: 860px) {
      border-left: 1px solid @xx-light-grey;
    }
  }

  .offer-action {
    flex-basis: 175px;
    padding: @spacing-xs;
    margin-top: auto;
    margin-bottom: auto;

    & > * {
      .lay-row();
      .--center-v();
      .--stretch-h();
      .--gap--s();

      padding: @spacing-xs;

      & > button {
        line-height: 1.25;
      }
    }
  }

  .offer-title {
    .title-s();

    font-size: 1.1em;
    color: @dark;
    text-decoration: none;
    cursor: pointer;
  }

  .offer-body_main {
    flex: 1 1 @bp-xs-max;
  }

  .offer-statuses {
    flex: 0 1 auto;
    min-width: 100px;
  }

  .offer-status {
    .chip();
    .--outline();

    display: inline-block;
  }

  .data-row {
    .lay-row();

    column-gap: @spacing-xs;
    line-height: 1.5;
    color: @dark-grey;

    & > * {
      &:not(:last-child) {
        padding-right: @spacing-xs;
        border-right: 1px solid @grey;
      }

      sup {
        .body-xs();

        vertical-align: top;
      }
    }
  }

  &:hover {
    background-color: @x-light-purple;

    .offer-price > * {
      background: white;
    }
  }
}

.offer-price {
  padding: @spacing-xs;
  padding-left: 0;

  & > * {
    .lay-col();
    .--center();

    height: 100%;
    padding: @spacing-xs;
    background-color: @x-light-purple;
    border-radius: @radius-xl;

    .offer-price_ttc {
      .title();
      .--txt-center();

      padding: 0 @spacing-s;
      color: @purple;

      & > * {
        .body-s();
      }
    }
  }
}
