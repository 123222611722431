@max-width: 400px;

.auth-screen {
  .lay-col();
  .--stretch();
  .--center-h();
  .--gap-v--m();
  .container-wrap();
  .page-content();

  padding-top: @spacing-xxxl;
  background: url(../img/auth-bg.svg) center bottom no-repeat;
  background-size: contain;

  & > * {
    max-width: @max-width;
  }
}

.auth-form {
  width: 100%;
  max-width: @max-width;

  &.--l {
    max-width: 700px;
  }

  .lost-password-cta {
    padding: @spacing-xxs 0;
    text-align: right;

    a {
      .link();
      .--underlined();
    }
  }

  .card-head {
    padding-bottom: @spacing-xs;
    border-bottom: none;
  }

  .card-footer,
  .card-body .form-footer {
    border-top: none;
  }
}
