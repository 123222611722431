._btn(@bgcolor: @grey, @txtcolor: white, @bordercolor: @bgcolor, @hoverbgcolor: darken(@bgcolor, 10%)) {
  .body-s();

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: @spacing-xl;
  padding: @spacing-xs @spacing;
  line-height: 1;
  color: @txtcolor;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background: @bgcolor;
  border: 1px solid @bordercolor;
  border-radius: @radius-l;
  transition: color 0.2s, background-color 0.2s, border 0.2s;

  .icon {
    display: inline-block;
    height: 1.2em;

    &.--left {
      margin-right: @spacing-xs;
    }
  }

  &:not([disabled]):hover {
    background-color: @hoverbgcolor;
    border-color: @hoverbgcolor;
  }

  &.active {
    box-shadow: inset 2px -2px 2px fade(@dark, 25%);
  }

  &.--btn-s {
    .body-s();

    height: @spacing-l * 1.15;
  }

  &.--icon {
    padding: @spacing-xs @spacing-xs;
    background-color: transparent;
    border: 1px solid transparent;

    &:hover {
      background-color: transparent;
      filter: brightness(0.9);
    }
  }

  &.--bg-blurred {
    background: fade(@xx-light-grey, 15%);
    border: 1px solid @xx-light-grey;
    border-radius: @radius-l;
    backdrop-filter: blur(10px);

    &:not([disabled]):hover {
      background: fade(@xx-light-grey, 50%);
      filter: brightness(1.1);
      border-color: darken(@xx-light-grey, 10%);
    }
  }

  &.--switch {
    ._link(@purple);
    .body-s();

    display: flex;
    align-items: center;
    padding: @spacing-xxs 0;

    &::before {
      box-sizing: border-box;
      display: block;
      flex: 0 1 auto;
      width: 16px;
      height: 16px;
      padding: 0;
      margin-right: @spacing-xs;
      font-size: 0;
      content: "";
      border: 6px solid white;
      border-radius: 2px;
      box-shadow: 0 0 0 1px @x-light-grey;
      transition: all 0.1s linear;
    }

    &.active {
      border-bottom: none;
      box-shadow: none;

      &::before {
        background: @purple;
        border-width: 3px;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.7;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.btn,
.btn-1 {
  ._btn(@purple);
}

.btn-light {
  ._btn(@x-light-purple);

  color: @purple;
  border: 1px solid @purple;
}

.btn-2 {
  ._btn(@xx-light-grey, @dark-grey);
}

.btn-warning {
  ._btn(@yellow);
}

.btn-danger {
  ._btn(@red);
}

.btn--outlined {
  ._btn(white, @purple, @xx-light-grey, @xxx-light-grey);

  &.btn-warning {
    ._btn(white, @yellow, @yellow, @xxx-light-grey);
  }
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;

  &::before {
    display: inline-block;
    width: 3em;
    height: 2em;
    margin-right: @spacing-xxs;
    content: "";
    background: url(../img/toggle-off.svg) no-repeat center left;
  }

  &.toggle-on::before {
    background-image: url(../img/toggle-on.svg);
  }
}

._link(@color: @grey) {
  .body();

  display: inline-block;
  padding: 0;
  color: @color;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: 0;

  &:not([disabled]):hover {
    background: none;
    opacity: 0.7;
  }

  &.active {
    .body-strong();

    border-bottom: 1px solid @color;
  }
}

.link {
  ._link(@dark);

  &.--underlined {
    text-decoration: underline;
  }

  &.--s {
    .body-s();
  }

  &.--xs {
    .body-xs();
  }

  &.--link-light {
    color: white;
  }

  &.--accent {
    color: @purple;
    text-decoration: underline;
  }

  &.--danger {
    color: @red;
    text-decoration: underline;
  }
}

.link-with-icon {
  .link();
  .--accent();
  .--xs();

  display: inline-flex;
  gap: @spacing-xxs;
  align-items: center;
}

.ghost-btn {
  ._btn();

  padding: 0;
  background-color: transparent;
  border: none;

  &:focus,
  &.focus,
  &:active,
  &.active {
    outline: none;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):not([disabled]):not(.selected):hover {
    background-color: transparent;
  }
}

.header_btn {
  ._btn(white, @purple);
  .--btn-s();
  .semibold();

  padding: @spacing-xs 7px;
}

.info_btn {
  .btn-1;

  width: @spacing-xs;
  height: @spacing-xs;
  padding: @spacing-xs;
  border-radius: 50%;
}
