._card(@base-padding: @spacing) {
  background-color: white;
  border: 1px solid @xxx-light-grey;
  border-radius: @radius-m;

  .card-body {
    padding: @spacing @base-padding;

    .form-footer {
      .card-body-footer();

      padding-top: @spacing;
      padding-bottom: 0;
      margin-top: @spacing;
    }
  }

  .card-head {
    padding: @base-padding*0.75 @base-padding;
    background-color: @x-light-purple;
    border-bottom: 1px solid @xx-light-grey;
    border-top-left-radius: @radius-m;
    border-top-right-radius: @radius-m;
  }

  .card-footer {
    padding: @base-padding*0.75 @base-padding;
    border-top: 1px solid @xx-light-grey;
  }
  .card-body-footer() {
    // adjusting card-footer styles when in card-body
    // used for card-body > .form-footer for example
    .card-footer();
    .lay-row();
    .--gap-h();
    .--center-h();

    padding-bottom: @base-padding*0.5;
    margin-right: -@base-padding;
    margin-left: -@base-padding;
  }

  .card-title {
    .title-xs();

    color: @purple;
  }
  .card-subtitle {
    .title-xxs();

    color: @light-grey;
  }

  @media screen and (max-width: @bp-xs-max) {
    .card-body,
    .card-head,
    .card-footer {
      padding-right: @spacing;
      padding-left: @spacing;
    }
  }
}

.card {
  ._card();
}

._dialog-card(@max-width: 500px;) {
  ._card();

  &:not(.panel) {
    .shadow();

    width: 100%;
    max-width: @max-width;
    border: 1px solid fade(@xx-light-grey, 40%);
  }

  &.panel {
    .shadow-left-s();
  }
}

.dialog-card-s {
  ._dialog-card(400px);
}
.dialog-card {
  ._dialog-card();
}
.dialog-card-m {
  ._dialog-card(600px);
}
.dialog-card-l {
  ._dialog-card(700px);
}
.dialog-card-full {
  ._dialog-card(100%);

  min-height: 100%;
}

.plan-card {
  .card();
  .shadow();
  .--txt-center();

  min-width: 280px;

  .card-body {
    .lay-col();
    .--center-h();

    height: 100%;
  }

  .plan-name {
    .title-xs();

    color: black;
  }

  .plan-price {
    .section();
    .title-l();

    width: 100%;
    color: @purple;

    sup {
      position: relative;
      top: -0.25em;
      font-size: 0.5em;
    }
  }

  select {
    .body-strong();

    width: auto;
    color: @purple;
    text-align: center;
    background-color: transparent;
    border: none;
  }

  .plan-features {
    .body-s();

    padding: 0;
    margin: 0;
    list-style-type: none;

    & > div {
      height: @spacing;
    }

    select {
      height: @spacing;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .plan-actions {
    width: 100%;
    padding-top: @spacing;
    margin-top: auto;

    .link {
      .link();
      .--s();
      .--accent();
    }
  }

  &.active {
    background-color: @x-light-purple;
    border-color: @purple;
  }
}
