.exchange_offer {
  .lay-col();
  .--gap-v--xs();
  .--center();

  width: 200px;
}

.exchange_price {
  min-width: 240px;
  padding: @spacing-xs;

  & > * {
    .lay-col();
    .--center();

    height: 100%;
    padding: @spacing-xs;
    background-color: @x-light-purple;
    border-radius: @radius-xl;

    .exchange_price_ttc {
      .title();

      color: @purple;

      & > * {
        .body-s();
      }
    }

    .exchange_price_ht {
      .body-xs();

      color: @grey;
    }
  }
}

.exchange-detail {
  .exchange_favorite-button,
  .exchange_edit-button {
    ._btn();
    .--icon();
    .--bg-blurred();
  }

  .exchange_data-display {
    .grid();
    .--2();

    flex: 10 1 500px;
  }

  .exchange_offer {
    flex: 1 0 300px;
    max-width: 100%;
    padding: 0;

    .exchange_price {
      width: 100%;
      padding: 0;

      & > div {
        padding: @spacing;
      }

      .exchange_actions {
        .grid();
        .--2-cols();

        row-gap: @spacing-xs;
        padding-top: @spacing-l;

        .btn-block {
          flex-basis: 100%;
          min-width: 200px;
          max-width: 100%;
        }
      }
    }
  }
}

.exchange-item {
  .lay-row();
  .--stretch();
  .--gap--s();

  margin: @spacing-s 0;
  color: black;
  text-decoration: none;
  border: 1px solid @xx-light-grey;
  border-radius: @radius-xl;
  transition: background-color 0.2s linear;

  .exchange-item_photo {
    position: relative;
    flex: 0 0 150px;
    overflow: hidden;
    border-radius: @radius-xl 0 0 @radius-xl;

    .exchange-item_photo_image {
      min-width: 150px;
      height: 100%;
      min-height: 100px;
      background: @purple no-repeat center center;
      background-image: url(../img/broken-image-white.svg);
      background-size: cover;
    }

    .exchange_favorite-button {
      ._btn();
      .--icon();
      .--bg-blurred();

      position: absolute;
      top: @spacing-xs;
      left: @spacing-xs;
    }

    @media (max-width: 656px) {
      flex: 1 0 100%;
      height: 200px;
      border-top-right-radius: @radius-xl;
      border-bottom-left-radius: 0;
    }
  }

  .exchange_body {
    .lay-row();
    .--gap--s();

    flex: 10 1 450px;
    padding: @spacing-xs;

    .exchange_ref {
      padding: @spacing-xxs @spacing-s;
      color: @light-grey;
    }

    .exchange_country-flag {
      display: inline-block;
      width: 1.5em;
      height: 1.2em;
      margin-bottom: -0.25em;
      background-position: center;
      background-size: cover;
    }
  }

  .exchange_title {
    .title-xs();

    color: @dark;
  }

  .exchange_body_main {
    flex: 1 1 @bp-xs-max;
  }

  .exchange_statuses {
    flex: 0 1 auto;
    min-width: 100px;

    & > * + * {
      margin-top: @spacing-xxs;
    }
  }

  .exchange_status {
    .chip();
    .--outline();

    display: inline-block;
  }

  .vehicle-data {
    .body-s();

    color: @dark-grey;

    .data-row {
      .lay-row();
      .body-s();

      column-gap: @spacing-xs;

      & > * {
        &:not(:last-child) {
          padding-right: @spacing-xs;
          border-right: 1px solid @grey;
        }
      }
    }
  }

  &:hover {
    background-color: @x-light-purple;

    .exchange_price > * {
      background: white;
    }
  }
}

.exchange_filters {
  .lay-col();
  .--gap();
  .content-block();
}

.exchange_extra-filters {
  .section();
  .content-block();
  .--bg();
  .--light();
  .grid();
  .--3();
}

.published-status {
  .chip();
  .--outline();
  .--success();

  display: none;
}

.my-exchanges {
  .published-status {
    display: block;
  }
}
