form {
  width: 100%;
}

.form-footer {
  .lay-col();
  .--center-h();
  .--gap-v();
  .section-xl();
}

.form-errors {
  .info();
  .info--error();
  .lay-col();
  .--center();

  row-gap: @spacing-xxs;
  list-style-type: none;

  & > * {
    text-align: center;
  }
}

.input {
  .body();

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: @spacing-xl;
  padding: @spacing-xs @spacing-s;
  color: @dark;
  background: white;
  border: 1px solid @x-light-grey;
  border-radius: @radius-l;
  appearance: none;

  &[disabled] {
    color: @light-grey;
    cursor: not-allowed;
    background: @xxx-light-grey;
  }

  &::placeholder {
    .body-s();

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: @grey;
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder {
    .body-xs();

    /* Microsoft Edge */
    color: @grey;
  }

  &:-ms-input-placeholder {
    .body-xs();

    /* Internet Explorer 10-11 */
    color: @grey;
  }

  &.--light {
    padding-left: @spacing-xs;
    background: transparent;
    border: 0;
    outline: none;
  }
}

.textarea {
  .input();

  height: auto;
  min-height: 8em;
  resize: vertical;
}

.select {
  .input();
  .body-s();

  padding-right: 50px;
  cursor: pointer;
  background-image: url(../img/icons/icon-caret-down-purple.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: @spacing;

  &[disabled] {
    color: @light-grey;
    cursor: not-allowed;
    background: @xxx-light-grey;
  }
}

.rmsc.multi-select {
  border: none;
  border-radius: @radius-l;

  .dropdown-heading {
    .select();

    padding-right: 30px;
    cursor: pointer;

    .gray {
      .body-s();

      color: @grey;
    }
  }

  .dropdown-heading-dropdown-arrow {
    display: none;
  }

  .dropdown-container {
    background: transparent;
    border: none;
    border-radius: @radius-l;

    &[aria-disabled] {
      cursor: not-allowed;
    }

    &:focus-within {
      box-shadow: none;
      .dropdown-heading {
        outline: auto;
      }
    }
  }

  .dropdown-content {
    .body-s();
  }

  & > [aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;

    .clear-selected-button {
      display: none;
    }
  }
}

.input-label {
  .body-s();

  margin-right: @spacing-xs;
  color: @dark;

  &.--s {
    .body-s();
  }

  &.--secondary {
    color: @grey;
  }
}

.input-tip {
  .body-xs();

  color: @light-grey;
}

.inputs-set {
  .lay-row();
  .--gap-h--s();
  .--no-wrap();

  & > .form-block {
    width: 100%;
  }
}

.empty-field {
  border-color: @purple !important;
}

.empty-field .dropdown-heading {
  border-color: @purple !important;
}

.form-block {
  & + & {
    margin-top: @spacing-s;
  }

  & > .input-label + .inputs-set > .form-block > .input-label {
    display: none;
  }

  &.--inline {
    .input-label + * {
      width: auto;
    }
  }
}

.lay-row,
.grid {
  .form-block {
    margin-top: 0;
  }
}

.file-drop {
  .lay-col();
  .--gap-v--s();
  .--center();
  .body-s();

  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80px;
  padding: @spacing;
  color: @dark;
  text-align: center;
  background: white;
  border: 2px dashed @x-light-grey;
  border-radius: @radius-l;

  &::before {
    display: inline-block;
    width: @spacing-l;
    height: @spacing-l;
    content: "";
    background: url(../img/icons/icon-upload-purple.svg) no-repeat center center;
    background-size: contain;
  }

  .upload-btn {
    .btn-1();
    .--btn-s();
  }

  .media-grid + & {
    margin-top: @spacing;
  }

  &.active {
    background: @x-light-grey;
  }

  & + .media-grid {
    margin-top: @spacing;
  }
}

fieldset {
  padding: 0;
  border: none;

  &[disabled] {
    .form-block {
      .lay-row();
      .--gap();
      .--center-v();
      .--distribute-h();
      .body-s();

      padding: @spacing-xs 0;
      border-bottom: 1px solid @xx-light-grey;
    }
    .input-label {
      .body-s();
      .body-strong();
    }
    .select {
      background: none;
      opacity: 1;
    }
    .input,
    .select {
      .body-s();

      flex: 1 10 auto;
      width: 1%;
      height: 1.2em;
      padding: 0;
      margin-left: auto;
      text-align: right;
      cursor: default;
      border: none;
      border-radius: 0;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }

      &[type="number"] {
        appearance: textfield; /* Firefox */
      }

      @media (max-width: @bp-s-max) {
        width: auto;
      }
    }

    .textarea {
      .body-s();

      min-height: auto;
      padding: @spacing;
      background: @xxx-light-grey;
      border: none;
    }
  }
}

.rich-text-display {
  .section();
  .--bg();
  .--grey();
  .body-s();

  white-space: pre-wrap;
}

.input-with-button {
  display: flex;

  .input {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button {
    .btn-light();

    padding-right: @spacing-s;
    padding-left: @spacing-s;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .icon {
      width: @spacing;
      height: @spacing;
      max-height: @spacing;
    }
  }
}
