@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700&display=swap");

/* FONTS */
.main-font {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}

/* WEIGHTS */
.thin {
  font-weight: 100;
}
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.semibold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}

/* TYPOGRAPHIC STYLES */
.title-xxs {
  .title();

  font-size: 0.9rem;
}
.title-xs {
  .title();

  font-size: 1rem;
}
.title-s {
  .title();

  font-size: 1.2rem;
}
.title {
  .main-font();
  .semibold();

  font-size: 1.4rem;
  line-height: 1.2;
}
.title-m {
  .title();

  font-size: 1.6rem;
}
.title-l {
  .title();

  font-size: 1.8rem;
}
.body-xs {
  .body();

  font-size: 0.7rem;
}
.body-s {
  .body();

  font-size: 0.8rem;
}
.body {
  .main-font();
  .regular();

  font-size: 0.9rem;
  line-height: 1.2;
}
.body-strong() {
  .semibold();
}

/* SEMANTIC TYPOGRAPHIC STYLES */
.page-title {
  .title-m();

  &.--underlined {
    padding-bottom: @spacing;
    border-bottom: 1px solid @x-light-grey;
  }
}
.section-title {
  .title-xs();

  color: @purple;
}

/* DEFAULT TYPOGRAPHIC STYLES */
html,
body {
  .main-font();

  padding: 0;
  margin: 0;
  color: @dark;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  .title();

  padding: 0;
  margin: 0;
}
p {
  padding: 0;
  margin: 0.5em 0;
  .body();
}

strong {
  .body-strong();
}
::selection {
  color: @selection-font-color;
  background-color: @selection-bg-color;
}

em {
  font-style: inherit;
  color: @purple;
}
